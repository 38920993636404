import React from 'react'
import parse from 'html-react-parser'
import { Link } from 'gatsby'
import { ArticlesList } from './hooks/articles'

const ArticlesSummary = props => {
  const { articleslist } = ArticlesList()
  //console.log('articleslist=', articleslist)

  return (
    <React.Fragment key={'articleslist'}>
      <h2>Web Hosting Article and Guides</h2>

      {articleslist.map((node, index) => {
        //console.log("node=",node)

        const articleTitle = node.node.Title.toLowerCase().trim()
        const removeStr = ' ' //variable
        const regex = new RegExp(removeStr, 'g') // correct way
        const articlePath = articleTitle.replace(regex, '-') // it works

        return (
          <React.Fragment key={index}>
            <h3>{node.node.Title} </h3>
            {parse(node.node.ShortDescription)} <br />
            <br />
            Read Full Article:&nbsp;
            <Link to={`/${articlePath}`}>
              {node.node.Title} <br /> <br />
            </Link>
          </React.Fragment>
        )
      })}
    </React.Fragment>
  )
}

export default ArticlesSummary
