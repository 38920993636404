import React from 'react'
import Layout from '../components/layout'
import Top10ListDup from '../components/top10dup'
import CategoriesSummary from '../components/categoriessummary'
import ArticlesSummary from '../components/articlesummary'
import Seo from '../components/seo'

const IndexPage = ({ data }) => (
  <Layout>
    <Seo
      title={'Best Web Hosting Services for ' + new Date().getFullYear()}
      description={
        'Find best web hosting companies with money back guarantee. Top 10 web hosting companies ranked by website hosting experts. Compare best web hosts.'
      }
    />

    <center>
      <h1>Best Web Hosting Services for 2021</h1>
      <h3>
        Successful websites start with a reliable web host. Choose your web host
        wisely.
      </h3>

    </center>  
    {/*     <div className="entry-header-wrapper">
      <div id="submain">
        <h1 itemProp="headline">Best Web Hosting Services for 2021</h1>
        <h3 itemProp="headline">Successful websites start with a reliable web host. Choose your web host
          wisely</h3>
      </div>
    </div> */}
    <Top10ListDup hostingid={10} onlylist={true} />
    <CategoriesSummary />
    <ArticlesSummary />
  </Layout>
)

export default IndexPage
